<template>
	<div class="picture">
		<frame :top1="top1" :top2="top2">
			<lists :titles='titles' :contentC='datas' @add='refresh()' @del='refresh()'></lists>
		</frame>
	</div>
</template>

<script>
	import lists from './componPicture/PictureList.vue' 
	import frame from '../public/Frame.vue';
	export default {
		name: 'pictures',
		data() {
			return {
				top1: '4-11',
				top2: ['4'],
				titles: ['编号', '相册名称', '封面', '图片数量', '描述', '操作'],
				datas: null,
			}
		},
		components: {
			frame,
			lists
		},
		created() {
			
		}
	}
</script>

<style scoped="scoped">
</style>
